// src/hooks/useFetchWheelStyles.ts
import { useCallback, useState } from 'react';
import { useFilterMenuStore, useStore } from '../lib/store';
import { WheelStyle } from '../lib/types';
import { apiEndpoint } from '../lib/utils';

const useFetchAndSetCurrentWheelData = () => {
  const setProductsLoading = useStore((state) => state.setProductsLoading);
  const setImgUrlBase = useStore((state) => state.setImgUrlBase);
  const imgUrlBase = useStore((state) => state.imgUrlBase);
  const setMoreItems = useStore((state) => state.setMoreItems);
  const moreItems = useStore((state) => state.moreItems);
  const setProducts = useStore((state) => state.setProducts);
  const currentBrandIds = useStore((state) => state.currentBrandIds);
  const sections = useFilterMenuStore((state) => state.sections);
  const setCurrentAutovizDbWheelInfo = useStore(
    (state) => state.setCurrentAutovizDbWheelInfo,
  );
  const setCurrentAutovizDbWheelModel = useStore(
    (state) => state.setCurrentAutovizDbWheelModel,
  );
  const setCurrrentAutosyncApiWheelStyle = useStore(
    (state) => state.setCurrentAutosyncApiWheelStyle,
  );
  const setCurrrentAutosyncApiWheelStyles = useStore(
    (state) => state.setCurrentAutosyncApiWheelStyles,
  );
  const currentAutosyncApiWheelStyle = useStore(
    (state) => state.currentAutosyncApiWheelStyle,
  );
  const currentAutosyncApiWheelStyles = useStore(
    (state) => state.currentAutosyncApiWheelStyles,
  );

  const [loading, setLoading] = useState<boolean>(true);

  const fetchAndSetCurrentWheelData = useCallback(
    async ({
      styleId,
      wheelId,
    }: { styleId?: string; wheelId?: string } = {}) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('data', JSON.stringify({ id: `${styleId}` }));

      const response = await fetch(`${apiEndpoint}/_get_wheel_detail.php`, {
        method: 'POST',
        body: formData,
      });

      const res = await response.json();

      const imgUrlBase = res?.data?.ImgUrlBase;
      setImgUrlBase(imgUrlBase);

      const moreItems = res?.data?.MoreItems;
      setMoreItems(moreItems);

      const autosyncApiWheelStyles = Array.isArray(res?.data?.Wheels)
        ? res.data.Wheels
        : [];

      const autoviz_db_wheel_model = res?.autoviz_db_wheel_model
        ? res.autoviz_db_wheel_model
        : null;

      let parsedWheelInfo = res?.autoviz_db_wheel_model?.wheel_info || null;
      try {
        parsedWheelInfo = JSON.parse(parsedWheelInfo);
      } catch (e) {
        console.log(`e`, e);
      }
      autoviz_db_wheel_model.wheel_info = parsedWheelInfo;

      setCurrentAutovizDbWheelModel(autoviz_db_wheel_model);
      setCurrentAutovizDbWheelInfo(parsedWheelInfo || null);

      autosyncApiWheelStyles.sort((a: any, b: any) => {
        if (a.Diameter !== b.Diameter) {
          return a.Diameter - b.Diameter;
        } else if (a.Width !== b.Width) {
          return a.Width - b.Width;
        } else if (a.Offset !== b.Offset) {
          return a.Offset - b.Offset;
        } else {
          return a.Bore - b.Bore;
        }
      });

      setCurrrentAutosyncApiWheelStyles(autosyncApiWheelStyles);

      let currentAutosyncApiWheelStyleIndex = 0;
      if (wheelId) {
        currentAutosyncApiWheelStyleIndex = autosyncApiWheelStyles.findIndex(
          (wheelStyle: WheelStyle) => wheelStyle.Id === Number(wheelId),
        );
      }
      // console.log(
      //   `currentAutosyncApiWheelStyleIndex`,
      //   currentAutosyncApiWheelStyleIndex,
      // );
      setCurrrentAutosyncApiWheelStyle(
        autosyncApiWheelStyles[currentAutosyncApiWheelStyleIndex],
      );

      // setLoading(false); @ia todo, set data loading to false and handle image loading separately
    },
    [],
  );

  return {
    fetchAndSetCurrentWheelData,
    loading,
    setLoading,
    imgUrlBase,
    moreItems,
  };
};

export default useFetchAndSetCurrentWheelData;
