import React, { useState } from 'react';

import { useStore } from '@/lib/store';
import { disableRightClick } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Brand, WheelStyleDetail } from '../lib/types';
import SvgIconPencil from './assets/svg-icon-pencil';
import ProductDetailSizeSelector from './product-detail-size-selector-chips';
import QuoteRequest from './quote-request';
import ShowProductAllDetails from './show-product-all-details';
import { WheelSubModelDisplay } from './text-displays-misc';
import { Button } from './ui/button';
import WheelInteractivesNavigationList from './wheel-interactives-navigation-list';

const WheelDetails: React.FC<{
  productDetail: any | null;
  wheelStyles: WheelStyleDetail[] | null;
  loading: boolean;
}> = ({ productDetail, wheelStyles, loading }) => {
  const brandLogosUrlBase = useStore((state) => state.brandLogosUrlBase);
  const brands = useStore((state) => state.brands);
  const brand =
    brands && productDetail?.Brand
      ? (brands[productDetail?.Brand] as Brand)
      : null;
  const currentBrand = useStore((state) => state.currentBrand);
  const resolvedBrand = brand || currentBrand;

  const [hasDisplays, setHasDisplays] = useState(false);

  const classNameProductOptionIcon = ` text-foreground rounded-lg p-1  pointer-events-auto`;

  const navigate = useNavigate();
  const navigateToConfigurator = () => {
    let productId = productDetail?.Id ? `/${productDetail?.Id}` : '';
    navigate(
      `/configurator/${productDetail?.StyleId}${productId}?Brand=${productDetail?.Brand}`,
    );
  };
  const currentAutovizDbWheelInfo = useStore(
    (state) => state.currentAutovizDbWheelInfo,
  );
  return (
    <div className="flex flex-col h-full bg-muted w-full md:bg-background md:overflow-y-scroll md:scrollbar-hidden overflow-hidden">
      <div
        className={`p-4 grid grid-cols-1 items-start justify-start
          xs:grid-cols-[auto,1fr]
          md:grid-cols-1`}
      >
        {/* Model and WheelSubModelDisplay Section: orders must be set higher in order to override previous orders */}
        <div
          className={`order-1
            xs:order-0
            md:order-4`}
        >
          <h2 className="text-4xl leading-tight">{productDetail?.Model}</h2>
          <WheelSubModelDisplay product={productDetail} />
        </div>

        {/* Brand Logo Section: orders must be set higher in order to override previous orders */}
        {resolvedBrand?.Logo && (
          <div
            onContextMenu={disableRightClick}
            className={`mb-6 order-0
              xs:place-self-end xs:place-content-end xs:order-1
              md:place-self-start md:place-content-start md:order-3`}
          >
            <img
              className=""
              src={`${brandLogosUrlBase}${resolvedBrand?.Logo}`}
              alt={resolvedBrand?.Brand}
            />
          </div>
        )}
      </div>

      <div className="overflow-y-scroll pb-36 md:pb-12 scrollbar-hidden  h-full w-full">
        <div className="p-0  mt-2  ">
          {wheelStyles && wheelStyles.length > 1 && (
            <div className="w-full pl-4 py-4 md:p-4">
              <ProductDetailSizeSelector
                items={wheelStyles}
                hasDisplays={hasDisplays}
                setHasDisplays={setHasDisplays}
              />
            </div>
          )}
        </div>
        <div className={`p-4`}>
          <ShowProductAllDetails
            productDetail={productDetail}
            hasDisplays={hasDisplays}
          />
        </div>
        <div className="mt-12  md:hidden p-4 ">
          <WheelInteractivesNavigationList
            product={productDetail}
            allowListMode={false}
            wheelInfo={currentAutovizDbWheelInfo}
          />{' '}
        </div>
      </div>

      <div className=" fixed bg-background bottom-0 safe-padding  w-full sm:relative  max-w-full z-20 shadow-lg shadow-black/50 md:shadow-none">
        <div className={` w-full   pb-6 flex gap-2 flex-col  px-4 pt-1`}>
          {/* {(productDetail?.data?.customize==="1" || wheelInfo?.customize==="1" || 1==1) && */}
          <Button
            variant={'ghost'}
            size={'default'}
            className=" flex text-foreground w-full  pl-0 hover:no-underline   "
            onClick={navigateToConfigurator}
          >
            <div
              className={twMerge(
                classNameProductOptionIcon,
                ' text-foreground border-primary flex w-[35px] justify-center',
              )}
            >
              <SvgIconPencil />
            </div>
            <div className="">
              <p className="font-normal">Customize</p>
            </div>
          </Button>
          <QuoteRequest />
        </div>
      </div>
    </div>
  );
};

export default React.memo(WheelDetails);
