// src/components/model-viewer.tsx

import { useModelViewer } from '@/hooks/useModelViewer';
import { useStore } from '@/lib/store';
import { cn } from '@/lib/utils';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import LoaderProgress from './loader-progress';
import { Button } from './ui/button';

interface ModelViewerProps {
  src: string;
  alt?: string;
  autoRotate?: boolean;
  cameraControls?: boolean;
  poster?: string;
  shadowIntensity?: number;
  cameraTarget?: string;
  scale?: string;
  onClick?: (e: React.MouseEvent) => void;
  handleModelLoaded?: (e: any) => void;
  width?: string;
  height?: string;
  disableZoom?: boolean;
  maxHeight?: string;
  ar?: boolean;
  arModes?: string;
  arScale?: string;
  modelViewerRefOverride?: any;
}

const ModelViewer: React.FC<ModelViewerProps> = forwardRef<
  HTMLDivElement,
  ModelViewerProps
>(
  (
    {
      src,
      alt = 'A 3D Wheel Model',
      ar = false,
      arModes = 'webxr',
      arScale = 'fixed',
      autoRotate = false,
      cameraControls = true,
      cameraTarget = 'auto auto auto',
      // cameraTarget = 'auto -8cm auto',
      poster,
      shadowIntensity = 1,
      onClick,
      scale = '0.5 0.5 0.5',
      handleModelLoaded,
      width = '100%',
      height = '100%',
      disableZoom = true,
      maxHeight = '800px',
      modelViewerRefOverride = null,
    },
    ref,
  ) => {
    const {
      isLoaded,
      fullyInitialized,
      modelViewerRef,
      // getMaterials,
      setIsLoaded,
      modelSrc,
      hasError,
      setHasError,
      modelViewerScriptsLoaded,
      // applyDataUrlToDataObject,
      setModelViewerRefState,
      // setModelViewerRefSelf,
      buildDataObjectFromModelMaterials,
    } = useModelViewer();

    // const show3DModel = useStore((state) => state.show3DModel);
    const setShow3DModel = useStore((state) => state.setShow3DModel);
    const setArIsPresenting = useStore((state) => state.setArIsPresenting);
    const arIsPresenting = useStore((state) => state.arIsPresenting);
    const arActivated = useStore((state) => state.arActivated);

    useEffect(() => {
      if (
        arIsPresenting === false &&
        arActivated === true &&
        isLoaded === true &&
        fullyInitialized === true
      ) {
      }
    }, [arIsPresenting]);

    // console.log(`MV RENDER`);

    const currentLoadedSrc = useRef('');
    useEffect(() => {
      const loadModel = async () => {
        currentLoadedSrc.current = src;
        setHasError(false);
        let loaderSrc = `https://autoviz.io/load/?path=${src}`;
        // setModelSrc(`https://autoviz.io/load/?path=${src}`);
        // let loaderSrc = `http://192.168.0.119:8087/load/?path=${src}`;
        try {
          const response = await fetch(loaderSrc, {
            method: 'HEAD',
            mode: 'cors',
          });

          if (!response.ok) {
            throw new Error(`Failed to load model: ${response.statusText}`);
          }

          // console.log(`setModelSrc`, loaderSrc);
          // setModelSrc(loaderSrc); // Set the model source only if the fetch is successful
        } catch (error) {
          console.error(`Error loading model:`, loaderSrc);
          setHasError(true);
        }
      };

      if (src && src !== currentLoadedSrc.current) loadModel();
    }, [src]);

    const id = `model-viewer`;
    const fieldOfView = '45deg';
    const fixedScale = '1 1 1';
    const loadInitialized = useRef(false);
    const mvLoadProgress = useRef(0);
    const eventBeforeRenderTriggered = useRef(false);

    //EVENT LISTENERS
    const _handleModelLoaded = (e: any) => {
      setIsLoaded(true);
      if (handleModelLoaded) handleModelLoaded(e);
      loadInitialized.current = true;
    };
    const _handleModelError = (e: any) => {
      console.error(`initiatePartsDataModel error`, e);
      setHasError(true);
    };
    const _handleArStatus = (e: any) => {
      let isArPresenting = e.detail.status === 'session-started' ? true : false;
      isArPresenting = e.detail.status === 'not-presenting' ? false : true;
      // Reset the model's scale when exiting AR
      if (modelViewerRef.current && isArPresenting === false) {
        modelViewerRef.current.scale = fixedScale;
        modelViewerRef.current['render-scale'] = fixedScale;
        modelViewerRef.current['max-field-of-view'] = fieldOfView;
        modelViewerRef.current['min-field-of-view'] = fieldOfView;
        modelViewerRef.current['field-of-view'] = fieldOfView;
      }
      setArIsPresenting(isArPresenting);
    };
    const _handleArTracking = (e: any) => {
      // console.log(`ar-tracking event`,e)
    };
    const _handleBeforeRender = (e: any) => {
      if (!modelViewerRef.current || eventBeforeRenderTriggered.current) return;
      eventBeforeRenderTriggered.current = true;
      setIsLoaded(true);
      setModelViewerRefState(modelViewerRef.current);
      buildDataObjectFromModelMaterials(modelViewerRef.current);
    };

    const progressHasInitialized = useRef(false);
    const _handleProgress = (e: any) => {
      // console.log(`event _handleProgress`, e.detail.totalProgress);
      if (progressHasInitialized.current) {
        setLoadProgress(e.detail.totalProgress);
      }
      progressHasInitialized.current =
        e.detail.totalProgress === 1 ? true : progressHasInitialized.current;
    };
    const [loadProgress, setLoadProgress] = useState(0);

    const setEventListeners = (
      method: 'addEventListener' | 'removeEventListener',
    ) => {
      // console.log(`method`, method);
      modelViewerRef?.current?.[method]('load', _handleModelLoaded);
      modelViewerRef?.current?.[method]('error', _handleModelError);
      modelViewerRef?.current?.[method]('ar-status', _handleArStatus);
      modelViewerRef?.current?.[method]('ar-tracking', _handleArTracking);
      modelViewerRef?.current?.[method]('before-render', _handleBeforeRender);
      modelViewerRef?.current?.[method]('progress', _handleProgress);
    };

    useEffect(() => {
      setHasError(false);
      if (modelViewerRef?.current && modelSrc) {
        setEventListeners('addEventListener');
        setModelViewerRefState(modelViewerRef.current);
        return () => {
          setEventListeners('removeEventListener');
        };
      }
    }, [modelViewerRef, modelSrc, modelViewerRef.current]);

    return (
      <div className="" onClick={onClick}>
        <LoaderProgress
          loadProgress={loadProgress}
          modelSrc={modelSrc}
          key={`loader-${modelSrc}`}
        />
        <div
          className={cn(
            'w-full mx-auto relative aspect-square transition-all duration-700',
            loadProgress === 1 ? 'opacity-100' : 'opacity-0',
          )}
        >
          <model-viewer
            ref={modelViewerRefOverride || modelViewerRef}
            id={id}
            key={modelSrc}
            src={modelSrc}
            touch-action="pan-y"
            alt={alt}
            ar={ar}
            ar-modes={arModes}
            ar-scale={arScale}
            loading="lazy"
            disable-tap
            auto-rotate={autoRotate}
            camera-controls={cameraControls}
            environment-image={`/images/studio_small_08_1k.jpg`}
            shadow-intensity={shadowIntensity}
            style={{ width: '100%', height, maxHeight }}
            scale={fixedScale}
            render-scale={fixedScale}
            camera-target={cameraTarget}
            camera-orbit="-30deg 75deg 300m"
            disable-zoom={disableZoom}
            exposure="1"
            field-of-view={fieldOfView}
            min-field-of-view={fieldOfView}
            max-field-of-view={fieldOfView}
          >
            <div slot="progress-bar"></div>
            <button slot="ar-button" className={`hidden`}></button>
          </model-viewer>
        </div>

        {hasError && (
          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[68%] h-[61%] flex items-center justify-center p-0 cursor-default z-[99999999]">
            <div className="flex flex-col justify-center items-center border-1 border-dotted bg-background border shadow-sm rounded-xl h-full w-full max-h-[80%] max-w-[80%] overflow-hidden p-2">
              Failed to load the 3D model.{' '}
              <Button
                className={`mt-4`}
                size={'sm'}
                variant={'outline'}
                onClick={() => {
                  setShow3DModel(false);
                  setHasError(false);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default React.memo(ModelViewer);
