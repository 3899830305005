// autoviz-component-monorepo/packages/product-catalog/src/components/wheel-interactives-navigation-list.tsx

import useShare from '@/hooks/useShare';
import { Share } from 'lucide-react';
import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useStore } from '../lib/store';
import SvgIconVehicle from './assets/svg-icon-vehicle';
import ButtonSeeInMySpace from './button-see-in-my-space';
import { Button } from './ui/button';
import { toast } from './ui/use-toast';

const WheelInteractivesNavigationList = ({
  product,
  allowListMode,
  wheelInfo,
}: {
  product: any;
  allowListMode?: boolean;
  wheelInfo?: any;
}) => {
  ///
  const { copyShareUrlToClipboard } = useShare();

  const handleShare = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    copyShareUrlToClipboard();
  };

  const setQrCodeOpen = useStore((state) => state.setQrCodeOpen);
  const setQrCodeTitle = useStore((state) => state.setQrCodeTitle);
  const setQrCodeSubTitle = useStore((state) => state.setQrCodeSubTitle);

  const handleTryOnInAr = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (isMobile || isTablet) {
      toast({
        description: 'Try on in AR is not supported on mobile devices yet',
        variant: 'destructive',
      });
    } else {
      setQrCodeOpen(true);
      setQrCodeTitle('Try on in AR');
      setQrCodeSubTitle(
        'Scan with your mobile device to virtually mount wheels on your vehicle',
      );
    }
  };

  return (
    <div className="flex flex-col flex-wrap xs:flex-row  gap-2 xs:items-center xs:justify-center">
      <ButtonSeeInMySpace
        product={product}
        allowListMode={allowListMode}
        wheelInfo={wheelInfo}
      />
      <Button
        onClick={(e) => handleTryOnInAr(e)}
        variant={'secondary'}
        className="py-10 text-base md:py-6 lg:text-sm xl:text-base"
      >
        <div className="flex md:flex-row flex-col  gap-2 items-center justify-center">
          <div>
            <SvgIconVehicle />
          </div>
          <div>
            <p className="">Try on in AR</p>
          </div>
        </div>
      </Button>
      <Button
        onClick={(e) => handleShare(e)}
        variant={'secondary'}
        className="py-10 text-base md:py-6 lg:text-sm xl:text-base"
      >
        <div className="flex flex-col  gap-2 items-center justify-center">
          <div>
            <Share className="w-5 h-5" strokeWidth={1.5} />
          </div>
          <div className="md:hidden">
            <p className="">Share</p>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default React.memo(WheelInteractivesNavigationList);

