// autoviz-component-monorepo/packages/product-catalog/src/components/button-see-in-my-space.tsx

import { pause } from '@/lib/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useStore } from '../lib/store';
import SvgIconDottedCube from './assets/svg-icon-dotted-cube';
import { Button } from './ui/button';

const ButtonSeeInMySpace = ({
  product,
  allowListMode,
  wheelInfo,
}: {
  product: any;
  allowListMode?: boolean;
  wheelInfo?: any;
}) => {
  const setQrCodeOpen = useStore((state) => state.setQrCodeOpen);
  const setQrCodeTitle = useStore((state) => state.setQrCodeTitle);
  const setQrCodeSubTitle = useStore((state) => state.setQrCodeSubTitle);

  const arModelViewerRef = useRef<any>(null);
  // const dataObject = useStore((state) => state.dataObject);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isArLoading, setIsArLoading] = useState(false);

  const currentAutovizDbWheelModel = useStore(
    (state) => state.currentAutovizDbWheelModel,
  );

  //AR
  // useEffect(() => {
  //   // Check when the arModelViewerRef is set
  //   if (arModelViewerRef.current) {
  //     if (arNeedsActivation) {
  //       activateAR();
  //     }
  //   }
  // }, [arModelViewerRef.current]);

  const activateAR = useCallback(() => {
    // if (navigator.xr) {
    //   navigator.xr.requestSession('immersive-ar', {}).then((session) => {
    //     xrSession = session;
    //     console.log(`session`, session);
    //     // handle session start
    //   });
    // }
    // console.log(`ACTIVATE AR`, arModelViewerRef.current);
    if (arModelViewerRef.current) {
      arModelViewerRef.current.activateAR();

      setTimeout(() => {
        setIsArLoading(false);
      }, 5000);
      setArActivated(true);
      setArNeedsActivation(false);
    } else {
      setArNeedsActivation(true);
    }
  }, [arModelViewerRef.current, currentAutovizDbWheelModel?.path]);

  const handleSeeInMySpace = async (e: any) => {
    // if (navigator.xr) {
    //   navigator.xr
    //     .requestSession('immersive-ar')
    //     .then((session) => {
    //       console.log('AR session started', session);
    //     })
    //     .catch((error) => {
    //       console.error('Failed to start AR session:', error);
    //     });
    // }

    // e.preventDefault();
    // e.stopPropagation();

    if (isMobile || isTablet) {
      if (currentAutovizDbWheelModel?.path != arModelSrc) {
        setIsArLoading(true);
        setArModelSrc(currentAutovizDbWheelModel?.path || '');
        // console.log(
        //   `SETTING PATH`,
        //   arModelViewerRef.current,
        //   currentAutovizDbWheelModel?.path,
        // );
      }
      await pause(100);
      activateAR();
    } else {
      setQrCodeOpen(true);
      setQrCodeTitle('See in my space');
      setQrCodeSubTitle(
        'Scan with your mobile device to see a wheel in your space',
      );
    }
  };

  const [isArPresenting, setArIsPresenting] = useState(false);
  //EVENT LISTENERS
  const _handleModelLoaded = (e: any) => {
    console.log(`AR _handleModelLoaded LOADED`, e);
    setIsLoaded(true);
    // activateAR();
  };

  const resetModelViewer = () => {
    setArModelSrc(''); // Clear the model source
    arModelViewerRef.current = null; // Reset the ref
    setTimeout(() => {
      // Delay to ensure the component fully unmounts before re-render
      setArModelSrc(currentAutovizDbWheelModel?.path || ''); // Load the new model after a short delay
    }, 300);
  };

  const _handleArStatus = (e: any) => {
    let isArPresenting = e.detail.status === 'session-started' ? true : false;
    isArPresenting = e.detail.status === 'not-presenting' ? false : true;
    // Reset the model's scale when exiting AR
    if (arModelViewerRef.current && isArPresenting === false) {
      console.log(`EXITING AR`);
      // const session = arModelViewerRef.current.getSession();
      // console.log(`session`, session);
      // if (session) {
      //   session.end(); // End the AR session explicitly
      // }
      setEventListeners('removeEventListener');
      resetModelViewer();
    }
    setArIsPresenting(isArPresenting);
  };

  const setEventListeners = (
    method: 'addEventListener' | 'removeEventListener',
  ) => {
    arModelViewerRef?.current?.[method]('load', _handleModelLoaded);
    arModelViewerRef?.current?.[method]('ar-status', _handleArStatus);
  };

  useEffect(() => {
    // console.log(`arModelViewerRef?.current`, arModelViewerRef.current);
    if (arModelViewerRef?.current) {
      console.log(`AR MOUNTED`);
      setEventListeners('removeEventListener');
      setEventListeners('addEventListener');
    }
    return () => {
      // console.log(`AR UNMOUNTING`);
      setEventListeners('removeEventListener');
    };
  }, [arModelViewerRef.current]);

  const arNeedsActivation = useStore((state) => state.arNeedsActivation);
  const arActivated = useStore((state) => state.arActivated);
  const setArActivated = useStore((state) => state.setArActivated);
  const setArNeedsActivation = useStore((state) => state.setArNeedsActivation);

  const [arModelSrc, setArModelSrc] = useState('');

  return (
    <div className={``} key={arModelSrc}>
      <div className={`h-0 w-0`}>
        <model-viewer
          ref={arModelViewerRef}
          src={arModelSrc}
          reveal="manual"
          ar
          ar-modes="webxr"
          ar-scale="fixed"
          loading="eager"
          disable-tap
          environment-image={`/images/studio_small_08_1k.jpg`}
          exposure="1"
        >
          <div slot="progress-bar"></div>
          <button slot="ar-button" className={`hidden`}></button>
        </model-viewer>
      </div>
      <Button
        loading={isArLoading}
        onClick={(e) => handleSeeInMySpace(e)}
        variant={'secondary'}
        className="py-10 text-base md:py-6 lg:text-sm xl:text-base w-full"
      >
        <div className="flex md:flex-row flex-col gap-2 items-center justify-center">
          <div>
            <SvgIconDottedCube />
          </div>
          <div className="max-w-fit break-words ">
            <p className="">See in my space</p>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default React.memo(ButtonSeeInMySpace);

