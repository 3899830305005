import { useStore } from '@/lib/store';
import { cn } from '@/lib/utils';
import { useEffect, useRef, useState } from 'react';

const LoaderProgress = ({
  loadProgress = 0,
  modelSrc,
}: {
  loadProgress: number;
  modelSrc: string;
}) => {
  const currentModelSrc = useRef('');
  const show3DModel = useStore((state) => state.show3DModel);

  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [loaderRendered, setLoaderRendered] = useState(true);
  const [_loaderProgress, _setLoaderProgress] = useState(0);

  const resetLoader = () => {
    setIsLoaderVisible(true);
    setLoaderRendered(true);
    _setLoaderProgress(0);
  };

  useEffect(() => {
    if (show3DModel && currentModelSrc.current !== modelSrc) {
      currentModelSrc.current = modelSrc;
      resetLoader();
    }
  }, [show3DModel, modelSrc]);

  useEffect(() => {
    // console.log(`loadProgress prop`, loadProgress);
    if (loadProgress === 1) {
      setIsLoaderVisible(false);
      setTimeout(() => {
        setLoaderRendered(false);
      }, 1000);
    } else {
      setLoaderRendered(true);
      setIsLoaderVisible(true);
    }
    _setLoaderProgress(loadProgress);
  }, [loadProgress]);

  return (
    <>
      {loaderRendered && (
        <div
          className={cn(
            'absolute top-[50%] left-0 right-0 bottom-0 mx-auto w-[50%] h-[20px] flex items-center justify-center bg-[#333333aa] z-[9999999]  rounded-full overflow-hidden drop-shadow-[0px_5px_10px_#00000066] dark:drop-shadow-[0px_5px_10px_#aaaaaa66] border-[.5px] border-r-primary border-b-primary border-r-primary transition-all duration-500',
            isLoaderVisible ? 'opacity-90' : 'opacity-0',
          )}
        >
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="text-white z-[999] flex flex-col items-center justify-center text-center h-[30px] font-bold text-[10px]">
              {Math.ceil(_loaderProgress * 100)}%
            </div>
            <div
              className="absolute top-0 left-0 h-full bg-black/70"
              style={{ width: `${_loaderProgress * 100}%` }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoaderProgress;
